<script lang="ts" setup>
  import SubscriptionBlockSkeleton from '~/components/skeletons/SubscriptionBlockSkeleton.vue';
  import SubscriptionCard from '~/components/cards/SubscriptionCard.vue';
  import VButton from '~/components/ui/VButton.vue';
  import type { ISubscription } from '~/types/common';
  import { getSubscriptions } from '~/services/modules/subscriptions.service';
  import { watchImmediate } from '@vueuse/shared';
  import VPromocodeWidget from '~/components/VPromocodeWidget.vue';

  const { state } = storeToRefs(useAuthStore());
  const { onReveal: onLogin } = useAuth();
  const { promocodeData } = storeToRefs(useSubscriptionsStore());
  const { setSubscriptions } = useSubscriptionsStore();
  const { subscriptions, pending } = storeToRefs(useSubscriptionsStore());

  const { data, refetch } = getSubscriptions({ options: { enabled: false } });
  onMounted(() => refetch());

  watchImmediate(data, subs => setSubscriptions(subs));

  const subscriptionModal = defineAsyncComponent(() => import('~/components/modals/subscription/Index.vue'));
  const { onReveal } = createModal(subscriptionModal);

  const openModal = (data?: ISubscription, price?: number) => {
    if (state.value.loggedIn) onReveal({ data, promoPrice: price, promocode: promocodeData.value });
    else {
      onLogin({
        onSuccessAndClose: () => onReveal({ data, promoPrice: price, promocode: promocodeData.value }),
      });
    }
  };
</script>

<template>
  <v-promocode-widget v-model="promocodeData" class="subscriptions-block__promocode" />
  <div class="subscriptions-block" id="subscription-block" v-if="!pending">
    <subscription-card
      v-for="(item, index) in subscriptions"
      :key="index"
      :loading="pending"
      :active="item?.active"
      @click="openModal(item?.data, item?.discountPrice)"
    >
      <template #title>{{ item?.title }}</template>
      <template #price>{{ item?.discount }} {{ item?.currency }}</template>
      <template #promocode v-if="item?.discountPrice">{{ item?.discountPrice }} {{ item?.currency }}</template>
      <template #discount v-if="item?.discountPercent">{{ item?.discountPercent }}%</template>
      <template #note>{{ item?.note }}</template>
      <template #button>
        <v-button
          :size="item?.active ? 'large' : 'normal'"
          :appearance="item?.active ? 'fill' : 'outline'"
          :color="item?.active ? 'white-2' : 'gradient'"
          class="subscriptions-block__btn"
        >
          Купить
        </v-button>
      </template>
    </subscription-card>
  </div>
  <subscription-block-skeleton id="subscription-block" v-else />
</template>

<style lang="scss" scoped>
  .subscriptions-block {
    //display: grid;
    //grid-template-columns: auto auto auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    @media (max-width: $tablet) {
      display: grid;
      justify-content: center;
      grid-template-columns: minmax(0, 500px) minmax(0, 500px);
    }
    @media (max-width: $tablet-small) {
      display: grid;
      justify-content: center;
      grid-template-columns: minmax(0, 500px);
    }
    @media (max-width: $retina) {
      padding-bottom: 24px;
    }

    &__btn:hover {
      transform: scale(1.03);
    }
  }

  .subscriptions-block__promocode {
    max-width: 736px;
    margin: 0 auto;

    &::v-deep(button) {
      min-width: 120px;
    }
  }
</style>
