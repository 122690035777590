<script lang="ts" setup>
  interface IProps {
    loading?: boolean;
    active?: boolean;
  }
  const props = defineProps<IProps>();
</script>

<template>
  <div class="subscription-card" :class="{ active: props.active }">
    <div class="subscription-card__body">
      <div class="subscription-card__title">
        <p><slot name="title" /></p>
        <span v-if="$slots.discount"><slot name="discount" /></span>
      </div>
      <div class="subscription-card__price" :class="{ promocode: $slots.promocode }">
        <p v-if="$slots.promocode"><slot name="promocode" /></p>
        <p :class="{ promo: $slots.promocode }"><slot name="price" /></p>
      </div>
    </div>
    <div class="subscription-card__note"><slot name="note" /></div>
    <div class="subscription-card__button">
      <slot name="button" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use 'assets/scss/variables/subscription-card.scss' as *;

  .subscription-card {
    $parent: &;

    display: grid;
    gap: 32px;
    flex: 1;
    padding: 24px;
    border-radius: 24px;
    cursor: pointer;

    &:not(.active) {
      @include gradientBorder();
    }

    &.active {
      padding: 32px;
      border-radius: 32px;
      background: map-get($subscriptionCard, bg-active);
      border: 1px solid map-get($subscriptionCard, border-active);
      flex: 1.5;
      height: 432px;
      #{$parent} {
        &__title {
          font-size: 36px;
          gap: 36px;
          span {
            font-size: 28px;
            line-height: 36px;
            padding: 8px 12px;
            background: map-get($subscriptionCard, discount-bg-active);
            border-radius: 16px;
          }
        }
        &__price {
          color: $text-color-main;
          font-size: 96px;
          font-style: normal;
          font-weight: 700;
          line-height: 120px;
          span {
            font-size: 18px;
          }
          &.promocode {
            font-size: 75px;
          }
        }
        &__note {
          font-size: 18px;
          color: $text-color-main;
        }
      }
    }
    &__body {
      display: grid;
      gap: 32px;
    }
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      color: $text-color-main;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding: 4px 8px;
        border-radius: 8px;
        background: map-get($subscriptionCard, discount-bg);
      ;
      }
    }
    &__price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-color-main;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      p {
        white-space: nowrap;
      }
      .promo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $text-color-3;
        font-size: 36px;
        font-weight: 700;
        line-height: 52px;
        white-space: nowrap;
        &:before {
          content: '';
          position: absolute;
          width: 110%;
          height: 2px;
          background-color: $main_red_light;
        }
      }
      &.promocode {
        font-size: 44px;
      }
    }
    &__price-promo {
      color: $text-color-3;
      font-size: 44px;
      font-weight: 700;
      line-height: 52px;
      white-space: nowrap;
    }
    &__note {
      color: $text-color-3;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    @media (max-width: $tablet) {
      padding: 24px;
      &.active {
        height: auto;
        padding: 24px;
        #{$parent} {
          &__title {
            font-size: 30px;
            gap: 30px;
            span {
              font-size: 24px;
              padding: 8px 12px;
            }
          }
          &__price {
            font-size: 60px;
            line-height: 70px;
            &.promocode {
              font-size: 60px;
            }
          }
          &__note {
            font-size: 18px;
          }
        }
      }
      &__title {
        font-size: 20px;
        line-height: 28px;
      }
      &__price {
        font-size: 52px;
        line-height: 60px;
      }
    }
    @media (max-width: $retina) {
      &.active {
        height: auto;
        order: -1;
        #{$parent} {
          &__title {
            font-size: 20px;
            line-height: 28px;
            gap: 30px;
            span {
              font-size: 18px;
              line-height: 28px;
              padding: 4px 8px;
              border-radius: 8px;
            }
          }
          &__price {
            font-size: 52px;
            line-height: 60px;
            &.promocode {
              font-size: 52px;
            }
          }
          &__note {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
